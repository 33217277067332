import './polyfills';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { sentryInit } from './sentry';

if (environment.production) {
  enableProdMode();
  sentryInit();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => {
  if (!environment.production) {
    console.error(err);
  }
});
