import { init, routingInstrumentation, setTags } from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import p from '../package.json';

export function sentryInit(): void {
  init({
    dsn: 'https://ef0fc3161cbe84adf0be5d2703dcbb0c@sentry.neoninfra.xyz/2',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost:4200', 'neonpass.neontest.xyz', 'devnet.neonpass.live', 'neonpass.live'],
        routingInstrumentation: routingInstrumentation
      })
    ],
    tracesSampleRate: 1.0
  });
  setTags({ version: p.version });
}
