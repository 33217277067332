import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrl: './content-loader.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true,
})
export class ContentLoaderComponent  implements OnInit {
  @Input() rows = 5;
  @Input() height = 50;
  @Input() gap = 8;
  @Input() horizontal = false;
  items: unknown[] = [];

  get styles(): string {
    return `height: ${this.height}px;`;
  }

  get wrapStyles(): string {
    return `flex-direction:${this.horizontal ? 'row' : 'column'};gap:${this.gap}px`;
  }

  ngOnInit() {
    this.items = Array(this.rows);
  }
}
