<div class='wallet' [attr.data-testid]='testId'>
  <div class='wallet-item'>
    <span class='label'>{{ label }}</span>
    <div class='network'>
      <img loading='lazy' class='icon' [src]='icon' [alt]='type' width='24' height='24' />
      <span class='text-white wallet-text'>{{ state$|async }}</span>
    </div>
  </div>
  @if (connected$|async) {
    <div class='flex flex-row gap-[16px] items-center'>
      @if (isSameNetwork) {
        <div class='wallet-item min-w-[120px] text-right'>
          <span class='label'>Address</span>
          <div class='text-white text-[16px] leading-[24px] cursor-pointer' (click)='copy()' [attr.data-testid]='"address-" + testId'>
            {{ addressView$|async }}
          </div>
        </div>
      } @else {
        <div class='flex flex-row gap-[7px] items-center self-center'>
          <img loading='lazy' src='/assets/icons/notification-error.svg' alt='Network error' width='38' height='38' />
          <span class='text-[16px] leading-[24px] text-white whitespace-nowrap'>Unsupported Network</span>
        </div>
      }
      <div>
        <button class='disconnect' (click)='handleConnect()' [disabled]='disabled' type='button'>
          <img loading='lazy' src='/assets/icons/close.svg' alt='Dialog close' width='24' height='24' />
        </button>
      </div>
    </div>
  } @else {
    <div class='flex-box-row items-center'>
      <button class='connect' (click)='handleConnect()' [disabled]='disabled' type='button'>
        {{connectWalletText}}
      </button>
    </div>
  }
</div>
