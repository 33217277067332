import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, distinctUntilChanged, SubscriptionLike, tap } from 'rxjs';
import { enterLeaveAnimations, fadeAnimation } from '../../../shared/animations';
import { itemUnsubscribe } from '../../../utils';
import { SolanaWallet, Wallet } from '../../../models';
import { SolanaWalletService, SolanaWalletsService } from '../../services';

@Component({
  selector: 'app-wallets-dialog',
  templateUrl: './wallets-dialog.component.html',
  styleUrls: ['./wallets-dialog.component.sass'],
  animations: [enterLeaveAnimations(200, 150), fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalletsDialogComponent implements OnInit, OnDestroy {
  private sub: SubscriptionLike[] = [];
  selected: Wallet<SolanaWallet>;
  isDestroy = false;
  step$ = new BehaviorSubject(0);
  trigger$: BehaviorSubject<any> = new BehaviorSubject(0);

  @HostBinding('class.dialog-container') size = true;

  @HostBinding('@enterLeave')
  get fromBehavior(): string {
    return this.isDestroy ? `fromBottomOut` : `fromBottom`;
  }

  get selectedName(): string {
    return this.selected.name;
  }

  get selectedIcon(): string {
    return `/assets/icons/wallet/${this.selected.icon}.svg`;
  }

  name = (item: Wallet<SolanaWallet>): string => item.name;
  icon = (item: Wallet<SolanaWallet>): string => `/assets/icons/wallet/${item.icon}.svg`;

  changeStep = (step: number): void => {
    this.step$.next(step);
    this.trigger$.next(step);
  };

  firstStep = (): void => {
    this.changeStep(0);
    this.s.connectionError$.next(``);
    this.s.loading$.next(false);
    this.s.hasError$.next(false);
    this.ref.disableClose = false;
  };

  open(item: Wallet<SolanaWallet>): void {
    this.selected = item;
    this.changeStep(3);
    this.s.loading$.next(true);
    this.ref.disableClose = true;
    setTimeout(() => {
      this.s.walletConnect(item.provider);
    }, 1000);
  }

  retry(): void {
    this.s.loading$.next(true);
    this.s.hasError$.next(false);
    this.s.connectionError$.next(``);
    setTimeout(() => {
      this.s.walletConnect(this.selected.provider);
    }, 1000);
  }

  close = (): void => {
    this.isDestroy = true;
    this.cdr.markForCheck();
    this.ref.overlayRef.detachBackdrop();
    setTimeout(() => {
      this.ref.close();
    }, 160);
  };

  ngOnInit(): void {
    this.sub.push(this.s.connected$.pipe(tap(c => {
      if (c) {
        this.close();
      }
    })).subscribe());
    this.sub.push(this.s.connectionError$.pipe(distinctUntilChanged(), tap(m => {
      this.s.hasError$.next(!!m);
      this.trigger$.next(m);
      if (m) {
        this.s.loading$.next(false);
      }
    })).subscribe());
  }

  ngOnDestroy(): void {
    itemUnsubscribe(this.sub);
    this.s.connectionError$.next(``);
  }

  constructor(public w: SolanaWalletsService, public s: SolanaWalletService, private ref: DialogRef, private cdr: ChangeDetectorRef) {
  }
}
