export function isObject(text: string): boolean {
  try {
    const result = JSON.parse(text);
    return typeof result === 'object' && result !== null;
  } catch (e) {
    return false;
  }
}

export function parseJson<T>(text: string): T {
  if (isObject(text)) {
    try {
      return JSON.parse(text);
    } catch (e: unknown) {
      console.log(e instanceof Error ? e.message : e);
    }
  }
  return {} as T;
}
