<div class='animation'></div>
<div class='banner-item left'></div>
<div class='banner-item center'>
  <p>
    <span>Join Neon EVM Points Program</span>
    <span><a href='https://points.neonevm.org' target='_blank'>Get Started Today!</a></span>
  </p>
</div>
<div class='banner-item right'>
  <button class='dialog-close' (click)='close()'>
    <svg-icon src='/assets/icons/close.svg'></svg-icon>
  </button>
</div>
