<div class='dialog-header'>
  <h2 class='dialog-title'>Select a token</h2>
  <button type='button' class='dialog-close' (click)='close()'>
    <img src='/assets/icons/close.svg' alt='Dialog close' width='24' height='24' />
  </button>
</div>
<div class='dialog-content'>
  @for (item of tokens$|async; track item.token.symbol) {
    <button class='token' (click)='select(item)'>
      <span class='token-item symbol'>
        <img loading='lazy' class='icon' [src]='icon(item)' [alt]='symbol(item)'>
        <span class='symbol'>{{ symbol(item) }}</span>
      </span>
      @if (amount(item)|async; as balance) {
        <span class='token-item amount'>{{ balance }}</span>
      }
    </button>
  }
</div>
