<div class='transfer from' [class.finished]='isFinished|async'>
  <img loading='lazy' [src]='from' alt='' class='icon' width='32' height='32' />
</div>
<div class='progress' [class.finished]='isFinished|async'>
  <div class='progress-line' [style.width.%]='progress$|async'></div>
  <div class='check' [class.finished]='isFinished|async'>
    @if (isSignature|async) {
      <div class='spinner'>
        <img class='animate-spin' loading='lazy' src='/assets/icons/progress-loader.svg' alt='' width='24' height='24' />
      </div>
    }
    @if (isSigned|async) {
      <img loading='lazy' src='/assets/icons/progress-check.svg' alt='' width='24' height='24' />
    }
  </div>
</div>
<div class='transfer to' [class.finished]='isFinished|async'>
  <img loading='lazy' [src]='to' alt='' class='icon' width='32' height='32' />
</div>
