import { Injectable } from '@angular/core';
import { Transaction } from '@solana/web3.js';
import { SPLToken } from '@neonevm/token-transfer-core';
import { TransactionRequest } from 'ethers';
import { BehaviorSubject, SubscriptionLike } from 'rxjs';
import { tap } from 'rxjs/operators';
import { itemUnsubscribe } from '../../utils';
import { Amount, NeonTokenTransaction, SolanaTokenTransaction } from '../../models';
import { SolanaWalletService } from './solana-wallet.service';

@Injectable({ providedIn: 'root' })
export class TransferTransactionService {
  solanaTransaction$: BehaviorSubject<SolanaTokenTransaction> = new BehaviorSubject<SolanaTokenTransaction>({ timestamp: Date.now() });
  neonTransaction$: BehaviorSubject<NeonTokenTransaction> = new BehaviorSubject<NeonTokenTransaction>({ timestamp: Date.now() });
  wNeonTransaction$: BehaviorSubject<NeonTokenTransaction> = new BehaviorSubject<NeonTokenTransaction>({ timestamp: Date.now() });
  private sub: SubscriptionLike[] = [];
  private interval = 2e4;

  solanaTransaction = async (a: Amount, t: SPLToken, method: (a: Amount, t: SPLToken) => Promise<Transaction>): Promise<Transaction> => {
    if (this.isTransactionExpired(this.solanaTransaction$.value, t, a)) {
      const transaction = await method(a, t);
      if (!transaction.recentBlockhash) {
        const { blockhash } = await this.s.connection.getLatestBlockhash('finalized');
        transaction.recentBlockhash = blockhash;
      }
      const timestamp = Date.now();
      this.solanaTransaction$.next({ transaction, timestamp, token: t, amount: a });
      return transaction;
    } else {
      return this.solanaTransaction$.value.transaction!;
    }
  };

  neonTransaction = async (a: Amount, t: SPLToken, method: (a: Amount, t: SPLToken) => Promise<TransactionRequest>): Promise<TransactionRequest> => {
    if (this.isTransactionExpired(this.solanaTransaction$.value, t, a)) {
      const transaction = await method(a, t);
      const timestamp = Date.now();
      this.neonTransaction$.next({ transaction, timestamp, token: t, amount: a });
      return transaction;
    }
    return this.neonTransaction$.value.transaction!;
  };

  wNeonTransaction = async (a: Amount, t: SPLToken, method: (a: Amount, t: SPLToken) => Promise<TransactionRequest>): Promise<TransactionRequest> => {
    if (this.isTransactionExpired(this.solanaTransaction$.value, t, a)) {
      const transaction = await method(a, t);
      const timestamp = Date.now();
      this.wNeonTransaction$.next({ transaction, timestamp, token: t, amount: a });
      return transaction;
    }
    return this.wNeonTransaction$.value.transaction!;
  };

  init(): void {
    this.sub.push(this.solanaTransaction$.pipe(tap(console.log)).subscribe());
    this.sub.push(this.neonTransaction$.pipe(tap(console.log)).subscribe());
  }

  destroy(): void {
    itemUnsubscribe(this.sub);
  }

  solanaClean(): void {
    this.solanaTransaction$.next({ timestamp: Date.now() });
  }

  neonClean(): void {
    this.neonTransaction$.next({ timestamp: Date.now() });
  }

  isTransactionExpired(trx: NeonTokenTransaction | SolanaTokenTransaction, t: SPLToken, a: Amount): boolean {
    const { transaction, token, timestamp, amount } = trx;
    const now = Date.now();
    return !transaction || token?.symbol !== t.symbol || amount?.toString() !== a.toString() || timestamp + this.interval <= now;
  }

  clean(): void {
    this.solanaClean();
    this.neonClean();
  }

  constructor(private s: SolanaWalletService) {
  }
}
