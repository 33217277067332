import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QuestionTooltipComponent, SmoothHeightComponent, TooltipComponent } from './components';
import { TooltipDirective } from './directives/tooltip.directive';

const components = [QuestionTooltipComponent, SmoothHeightComponent, TooltipComponent];
const directives = [TooltipDirective];

@NgModule({
  declarations: [...components, ...directives],
  exports: [...components, ...directives],
  imports: [CommonModule, AngularSvgIconModule]
})
export class SharedModule {
}
