<div class='flex flex-row justify-between items-start w-full'>
  <div class='flex flex-col justify-center gap-[3px] min-h-[64px] w-full'>
    <label class='label' for='token-amount'>Send</label>
    <input [ngModel]='value' (input)='inputValue($event)' (touchend)='onTouched()'
           [disabled]='disabled' (focus)='focus = true' (blur)='focus = false' inputmode='decimal'
           [mask]='mask' thousandSeparator='' [separatorLimit]='separatorLimit' name='token-amount' id='token-amount'
           placeholder='0' class='amount' type='text' data-testid='token-amount' #amount autocomplete='off'>
  </div>
  <div class='flex flex-col items-end gap-[8px] min-h-[64px] min-w-[126px]'>
    <ng-content></ng-content>
    <div class='balance'>
      <app-counter [value]='(balance$|async)!' class='balance'></app-counter>
      @if (balance$|async; as b) {
        <button type='button' class='max' [disabled]='maxDisabled' (click)='max()'
                data-testid='token-amount-max-button'>Max</button>
      }
    </div>
  </div>
</div>
