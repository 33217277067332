import { Injectable } from '@angular/core';
import { PublicKey } from '@solana/web3.js';
import { GasToken, NeonEmulate } from '@neonevm/token-transfer-core';
import { BehaviorSubject, filter, Observable, ReplaySubject, SubscriptionLike, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Big } from 'big.js';
import { CHAIN_ID, itemUnsubscribe } from '../../utils';
import { environment } from '../../environments/environment';
import { GasTokenResponse, LocalStorage, NeonProxyStatus, TransferDirection, TransferToken } from '../../models';
import { HttpRpcClient } from '../../app/services';

@Injectable({ providedIn: 'root' })
export class ProxyStatusService {
  gasToken$: BehaviorSubject<GasToken> = new BehaviorSubject<GasToken>({} as GasToken);
  private _proxyStatus: NeonProxyStatus;
  private _proxyStatus$: ReplaySubject<NeonProxyStatus> = new ReplaySubject<NeonProxyStatus>(0);
  private _tryUtm = false;
  private subs: SubscriptionLike[] = [];

  get chainId(): number {
    const { tokenChainId } = this.gasToken$.value ?? {};
    return tokenChainId ? parseInt(tokenChainId, 16) : CHAIN_ID;
  }

  get proxyUrl(): string {
    const { tokenName } = this.gasToken$.value;
    const proxyUrl = this.api.rpcUrl;
    return tokenName ? `${proxyUrl}/${tokenName.toLowerCase()}` : proxyUrl;
  }

  get programId(): PublicKey {
    return new PublicKey(this.proxyStatus.neonEvmProgramId);
  }

  get tokenMint(): PublicKey {
    const { tokenMint } = this.gasToken$.value;
    return new PublicKey(tokenMint ? tokenMint : environment.neon.token_mint);
  }

  set proxyStatus(size: NeonProxyStatus) {
    this.proxyStatusEmit(size);
  }

  get proxyStatus(): NeonProxyStatus {
    if (!this._proxyStatus && !this._tryUtm) {
      const status = localStorage.getItem(LocalStorage.proxyStatus) ?? '';
      if (status?.length > 0) {
        try {
          this.proxyStatus = JSON.parse(status);
        } catch (e) {
          console.log(e);
        }
      }
      this._tryUtm = true;
    }
    return this._proxyStatus;
  }

  get proxyStatus$(): Observable<NeonProxyStatus> {
    return this._proxyStatus$.pipe(filter(s => !!s));
  }

  gasTokenSelect = (gasToken: GasToken): void => {
    this.gasToken$.next(gasToken);
  };

  currentGasToken = (tokenList: GasToken[]): void => {
    if (tokenList.length > 0) {
      this.gasToken$.next(tokenList[0]);
    }
  };

  neonTokenAmount(token: TransferToken, direction: TransferDirection): Big {
    const amount = token?.amountView(direction);
    const mint = [environment.neon.token_mint];
    return mint.includes(token?.token.address_spl) ? new Big(amount?.toFixed(environment.neon.decimals.token) ?? 0) : amount;
  }

  neonTokenAmount$(token: TransferToken, direction: TransferDirection): Observable<Big> {
    return token.amountView$(direction).pipe(map((amount) => {
      const mint = [environment.neon.token_mint];
      return mint.includes(token?.token.address_spl) ? new Big(amount?.toFixed(environment.neon.decimals.token) ?? 0) : amount;
    }));
  }

  neonEmulate(params: string[] = []): Observable<NeonEmulate> {
    return this.api.rpc<NeonEmulate>('neon_emulate', params, this.proxyUrl);
  }

  evmParams(): Observable<NeonProxyStatus> {
    return this.api.rpc<NeonProxyStatus>('neon_getEvmParams', [], this.proxyUrl);
  }

  nativeTokenList(): Observable<GasTokenResponse[]> {
    return this.api.rpc<GasTokenResponse[]>('neon_getNativeTokenList', [], this.proxyUrl);
  }

  init(): void {
    this.subs.push(this.gasToken$.pipe(
      filter(t => !!t?.tokenChainId),
      switchMap(_ => this.evmParams()),
      tap(this.proxyStatusEmit)).subscribe());
  }

  destroy(): void {
    itemUnsubscribe(this.subs);
  }

  private proxyStatusEmit = (size: NeonProxyStatus): void => {
    this._proxyStatus = size;
    this._proxyStatus$.next(this._proxyStatus);
    localStorage.setItem(LocalStorage.proxyStatus, JSON.stringify(this._proxyStatus));
  };

  constructor(private api: HttpRpcClient) {
  }
}
