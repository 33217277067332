<div class='dialog-header'>
  <div class='flex flex-row gap-[8px] items-center w-full'>
    @switch (step$.value) {
      @default {
        <img loading='lazy' src='/assets/icons/wallet.svg' alt='' width='40' height='40' />
        <h2 class='dialog-title'>Connect a Solana wallet</h2>
      }
      @case (1) {
        <button type='button' class='dialog-close' (click)='firstStep()'>
          <img loading='lazy' src='/assets/icons/arrow-back.svg' class='w-[20px] h-[20px]' alt='Go back' width='20' height='20' />
        </button>
        <h2 class='dialog-title'>Get started</h2>
      }
      @case (2) {
        <button type='button' class='dialog-close' (click)='changeStep(1)'>
          <img loading='lazy' src='/assets/icons/arrow-back.svg' class='w-[20px] h-[20px]' alt='Go back' width='20' height='20' />
        </button>
        <h2 class='dialog-title'>Popular wallets to get started</h2>
      }
      @case (3) {
        <button type='button' class='dialog-close' (click)='firstStep()' [disabled]='s.loading$.value'>
          <img loading='lazy' src='/assets/icons/arrow-back.svg' class='w-[20px] h-[20px]' alt='Go back' width='20' height='20' />
        </button>
        <h2 class='dialog-title w-full text-center'>{{ selectedName }}</h2>
      }
    }
  </div>
  <button type='button' (click)='close()' class='dialog-close' [disabled]='s.loading$.value'>
    <img loading='lazy' src='/assets/icons/close.svg' alt='Dialog close' width='24' height='24' />
  </button>
</div>
<app-smooth-height [trigger]='trigger$|async'>
  @switch (step$|async) {
    @default {
      <div class='dialog-content'>
        <div class='wallets flex-box-col'>
          <ng-container *ngFor='let item of w.wallets;trackBy:w.trackByFn'>
            <button class='wallet-button flex-box-row' (click)='open(item)'>
              <img loading='lazy' [src]='icon(item)' [alt]='item.name' width='32' height='32' />
              <span>{{ name(item) }}</span>
            </button>
          </ng-container>
        </div>
      </div>
      <div class='dialog-footer flex-box-row'>
        <button type='button' class='no-wallet' (click)='changeStep(1)'>
          <svg-icon src='/assets/icons/wallet-f.svg' class='w-[20px] h-[20px]'></svg-icon>
          <span class='text'>I don’t have a wallet</span>
        </button>
      </div>
    }
    @case (1) {
      <div class='dialog-content wallets-background flex-box-col'>
        <div class='wallets-bg'>
          <img loading='lazy' src='/assets/images/wallet-coinbase.svg' alt='coinbase' class='wallet coinbase' width='50' height='48'>
          <img loading='lazy' src='/assets/images/wallet-phantom.svg' alt='phantom' class='wallet phantom' width='50' height='48'>
          <img loading='lazy' src='/assets/images/wallet-glow.svg' alt='glow' class='wallet glow' width='50' height='48'>
          <img loading='lazy' src='/assets/images/wallet-solflire.svg' alt='solflire' class='wallet solflire' width='50' height='48'>
        </div>
        <h2>Start Exploring Web3</h2>
        <p>Your wallet is the gateway to all things Solana,<br />
          the magical technology that makes it<br />
          possible to explore web3.</p>
      </div>
      <div class='dialog-footer flex-box-row'>
        <button type='button' class='no-button flex-box-row' (click)='changeStep(2)'>
          <span class='text'>Choose your first wallet</span>
          <svg-icon src='/assets/icons/arrow-right.svg' class='w-[20px] h-[20px]'></svg-icon>
        </button>
      </div>
    }
    @case (2) {
      <div class='dialog-content'>
        <div class='wallets flex-box-col'>
          <ng-container *ngFor='let item of w.wallets;trackBy:w.trackByFn'>
            <a class='wallet-button flex-box-row' [href]='item.link' target='_blank'>
              <img loading='lazy' [src]='icon(item)' [alt]='item.name' width='32' height='32' />
              <span>{{ name(item) }}</span>
            </a>
          </ng-container>
        </div>
      </div>
    }
    @case (3) {
      <div class='dialog-content wallet-connect flex-box-col mb-[20px]'>
        <div class='flex justify-center items-center h-[152px]'>
          @if (s.loading$|async) {
            <div class='wallet-loading'></div>
          }
          <div class='relative'>
            <img loading='lazy' [src]='selectedIcon' [alt]='selectedName' width='80' height='80' />
            @if (s.hasError$.value) {
              <img loading='lazy' src='/assets/icons/wallet-error.svg' alt='error' class='icon-error' />
            }
          </div>
        </div>
        @if (s.hasError$|async) {
          <h2 class='error'>Request Cancelled</h2>
          <p class='mb-[20px]'>Connection can be declined if a previous<br /> request is still active</p>
          <button class='button py-[8px] px-[16px] flex-box-row' (click)='retry()'>
            <img loading='lazy' src='/assets/icons/retry.svg' alt='' width='21' height='20' />
            <span class='text-[#9E77ED]'>Try again</span>
          </button>
        } @else {
          <h2>Requesting Connection</h2>
          <p>Accept connection request in the wallet</p>
        }
      </div>
    }
  }
</app-smooth-height>
