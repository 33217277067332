import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@angular/cdk/dialog';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import {
  NeonChainService,
  PriorityFeeService,
  SentryLogService,
  SolanaWalletService,
  TokenAccessService
} from './services';
import {
  ChainSwitchComponent,
  CounterComponent,
  LoaderComponent,
  LoaderSmallComponent,
  NeonTransactionFeeComponent,
  SolanaPriorityFeeComponent,
  TokenAmountComponent,
  TokenReceiveComponent,
  TokensSelectButtonComponent,
  TokensSelectDialogComponent,
  TokenTransferFormComponent,
  TransferErrorsComponent,
  TransferGasFeesComponent,
  TransferProgressComponent,
  WalletButtonComponent,
  WalletConnectComponent,
  WalletsDialogComponent
} from './components';
import { ContentLoaderComponent } from './components/content-loader/content-loader.component';
import { SharedModule } from '../shared/shared.module';

const components = [
  ChainSwitchComponent,
  CounterComponent,
  LoaderComponent,
  LoaderSmallComponent,
  WalletConnectComponent,
  WalletButtonComponent,
  TokenAmountComponent,
  TokenReceiveComponent,
  TokenTransferFormComponent,
  TransferGasFeesComponent,
  TransferErrorsComponent,
  TransferProgressComponent,
  WalletsDialogComponent,
  NeonTransactionFeeComponent,
  SolanaPriorityFeeComponent,
  TokensSelectButtonComponent,
  TokensSelectDialogComponent
];

const providers = [
  SolanaWalletService,
  NeonChainService,
  TokenAccessService,
  SentryLogService,
  PriorityFeeService
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    DialogModule,
    PortalModule,
    AngularSvgIconModule,
    NgxMaskModule.forRoot({ dropSpecialCharacters: true }),
    NgOptimizedImage,
    SharedModule,
    ContentLoaderComponent
  ],
  providers: [...providers]
})
export class TokenTransferModule {
}
