import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-smooth-height',
  templateUrl: './smooth-height.component.html',
  styleUrl: './smooth-height.component.sass',
  animations: [trigger('grow', [
    transition('void <=> *', []),
    transition('* <=> *', [
      style({ height: '{{startHeight}}px' }),
      animate('.3s ease')
    ], { params: { startHeight: 0 } })
  ])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmoothHeightComponent implements OnChanges {
  @Input() trigger: any;
  startHeight: number;

  @HostBinding('@grow')
  get grow() {
    return { value: this.trigger, params: { startHeight: this.startHeight } };
  }

  setStartHeight(): void {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(): void {
    this.setStartHeight();
  }

  constructor(private element: ElementRef) {
  }
}
