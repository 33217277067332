import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TransferDirection } from '../../../models';
import { rotateState } from '../../../shared/animations';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-wallet-connect',
  templateUrl: './wallet-connect.component.html',
  styleUrls: ['./wallet-connect.component.sass'],
  animations: [rotateState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalletConnectComponent {
  @Input() direction: TransferDirection;
  @Input() disabled: ReplaySubject<boolean> = new ReplaySubject<boolean>(0);
  @Input() sameNetwork: boolean;
  @Output() directionChange = new EventEmitter();
  state: string = 'default';

  get transferFromTo(): { from: TransferDirection, to: TransferDirection } {
    const from = this.direction;
    const to = this.direction === TransferDirection.neon ? TransferDirection.solana : TransferDirection.neon;
    return { from, to };
  }

  toggleDirection(): void {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
    this.directionChange.emit(this.direction === TransferDirection.neon ? TransferDirection.solana : TransferDirection.neon);
    this.ga.event('change_direction_click');
  }

  constructor(private ga: GoogleAnalyticsService) {}
}
