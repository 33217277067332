import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, from, Observable, of, SubscriptionLike, tap } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { itemUnsubscribe } from '../../../utils';
import { TransferDirection } from '../../../models';
import { NotificationService } from '../../../notifications';
import { SolanaWalletService, WalletConnectService } from '../../services';
import { WalletsDialogComponent } from '../wallets-dialog/wallets-dialog.component';

@Component({
  selector: 'app-wallet-button',
  templateUrl: './wallet-button.component.html',
  styleUrls: ['./wallet-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalletButtonComponent implements OnDestroy {
  @Input() type: TransferDirection;
  @Input() testId: string = '';
  @Input() label: 'From' | 'To' = 'From';
  @Input() disabled: boolean | null = false;
  @Input() sameNetwork: boolean = false;
  private subs: SubscriptionLike[] = [];

  get isSameNetwork(): boolean {
    return this.type === TransferDirection.neon ? this.sameNetwork : true;
  }

  get connectWalletText(): string {
    switch (this.type) {
      case TransferDirection.solana:
        return `Connect Solana Wallet`;
      case TransferDirection.neon:
        return `Connect EVM Wallet`;
      default:
        return `Connect Wallet`;
    }
  }

  get connected(): string {
    return this.connected$.value ? 'connected' : '';
  }

  get icon(): string {
    return `/assets/icons/${this.type}.svg`;
  }

  get connected$(): BehaviorSubject<boolean> {
    return this.type === TransferDirection.neon ?
      this.wc.connected$ : this.solana.connected$;
  }

  get addressView$(): Observable<string> {
    return this.type === TransferDirection.neon ?
      this.wc.addressView$ : this.solana.pubkey$;
  }

  get state$(): Observable<string> {
    return of(this.type === TransferDirection.neon ? 'Neon' : 'Solana');
  }

  handleConnect(): void {
    if (this.connected) {
      this.disconnect();
    } else {
      this.connect();
    }
  }

  connect(): void {
    if (this.type === TransferDirection.neon) {
      this.wc.open();
      this.ga.event('neon_wallet_connect'); // GA MM connect event
    } else {
      const config = { backdropClass: `dialog-backdrop` };
      const ref = this.d.open(WalletsDialogComponent, config);
      this.subs.push(ref.outsidePointerEvents.pipe(tap(d => {
        if (!ref.disableClose) {
          d.stopPropagation();
          ref.componentRef?.instance.close();
        }
      })).subscribe());
      this.ga.event('solana_wallet_connect'); // GA Phantom connect event
    }
  }

  disconnect(): void {
    if (this.type === TransferDirection.neon) {
      this.wc.disconnect();
    } else {
      this.solana.disconnect();
    }
  }

  copy(): void {
    const address = this.type === TransferDirection.neon ? this.wc.address : this.solana.publicKey.toBase58();
    this.subs.push(from(navigator.clipboard.writeText(address)).pipe(tap(() => {
      this.n.info({ title: 'Address was copied', close: false });
    })).subscribe());
  }

  ngOnDestroy(): void {
    itemUnsubscribe(this.subs);
  }

  constructor(private solana: SolanaWalletService, private n: NotificationService,
              private d: Dialog, private ga: GoogleAnalyticsService, public wc: WalletConnectService) {
  }
}
