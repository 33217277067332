<img [src]='icon' class='icon' alt='' width='38' height='38' />
<div class='flex flex-col justify-center w-full'>
  <ng-template appNotificationTemplate></ng-template>
  @if (isContentText) {
    <div class='flex flex-col flex-grow gap-[4px] justify-center'>
      <h3 class='text-[14px] leading-[20px] text-white'>{{ item.title }}</h3>
      @if (item.message) {
        <p class='text-[14px] leading-[20px] mb-0 text-[#CECFD2]' [innerHTML]='item.message'></p>
      }
    </div>
  }
</div>
@if (!item.closeByAction && item.close) {
  <div>
    <button (click)='onClose($event)' class='dialog-close m-[-8px]' type='button'>
      <img src='/assets/icons/close.svg' alt='Notification close' width='24' height='24' />
    </button>
  </div>
}
