import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-question-tooltip',
  templateUrl: './question-tooltip.component.html',
  styleUrls: ['./question-tooltip.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionTooltipComponent {
  @Input() text = '';
}
