import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { combineLatest, map, Observable } from 'rxjs';
import { ProxyStatusService, TokensListService } from '../../services';
import { TokensDialogData, TransferDirection, TransferToken } from '../../../models';
import { SOL, W_NEON } from '../../../utils';
import { enterLeaveAnimations } from '../../../shared/animations';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-token-select-dialog',
  templateUrl: './tokens-select-dialog.component.html',
  styleUrl: './tokens-select-dialog.component.sass',
  animations: [enterLeaveAnimations(200, 150)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokensSelectDialogComponent {
  isDestroy = false;

  @HostBinding('class.dialog-container') size = true;

  @HostBinding('@enterLeave')
  get fromBehavior(): string {
    return this.isDestroy ? `fromBottomOut` : `fromBottom`;
  }

  get tokens$(): Observable<TransferToken[]> {
    return combineLatest([this.tokens.tokens$, this.proxy.gasToken$]).pipe(map(([tokens, gasToken]) => {
      const symbols = this.d.direction === TransferDirection.solana ? [W_NEON] : [SOL];
      return this.tokens.tokensOrderByBalance(tokens.filter(item => !symbols.includes(item.token.symbol)), this.d.direction);
    }));
  }

  icon(token: TransferToken): string {
    const icon = token.token.logoURI.split('/').pop();
    return icon ? `/assets/tokens/${icon}` : `/assets/icons/token.png`;
  }

  symbol(token: TransferToken): string {
    return token.token.symbol ?? ``;
  }

  amount(token: TransferToken): Observable<string> {
    return this.proxy.neonTokenAmount$(token, this.d.direction).pipe(map(b => {
      return b.gt(0) ? b.gt(1e-6) ? b.toString() : `<0.000001` : '0.00';
    }));
  }

  loading(token: TransferToken): Observable<boolean> {
    return token[this.d.direction].pipe(map(({ status }) => status === 'loading'));
  }

  disabled(token: TransferToken): boolean {
    const { balance } = token[this.d.direction].value;
    if (balance) {
      const { amount } = balance;
      return typeof amount === 'bigint' ? amount <= 0n : typeof amount === 'number' ? amount <= 0 : false;
    }
    return true;
  }

  select(token: TransferToken): void {
    this.ga.event(`asset_selected_${token.token.symbol || ''}`);
    this.ref.close(token);
  }

  close(): void {
    this.isDestroy = true;
    this.cdr.markForCheck();
    this.ref.overlayRef.detachBackdrop();
    setTimeout(() => {
      this.ref.close();
    }, 160);
  }

  constructor(public tokens: TokensListService, private proxy: ProxyStatusService, @Inject(DIALOG_DATA) private d: TokensDialogData,
              private ref: DialogRef, private cdr: ChangeDetectorRef, private ga: GoogleAnalyticsService) {
  }
}
