import { AbstractControl } from '@angular/forms';
import { Big } from 'big.js';
import { isNumber } from './math';

export class FormValidators {
  static maxBig(value: Big) {
    return (control: AbstractControl) => {
      const value = new Big(isNumber(control.value) ? control.value : 0);
      if (value.gt(value)) {
        return { max: true };
      }
      return null;
    };
  }
}
