import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, SubscriptionLike, tap } from 'rxjs';
import { itemUnsubscribe } from '../../../utils';
import { TokensDialogData, TransferDirection, TransferToken } from '../../../models';
import { TokensSelectDialogComponent } from '../tokens-select-dialog/tokens-select-dialog.component';

@Component({
  selector: 'app-token-select-button',
  templateUrl: './tokens-select-button.component.html',
  styleUrl: './tokens-select-button.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TokensSelectButtonComponent),
    multi: true
  }]
})
export class TokensSelectButtonComponent implements ControlValueAccessor, OnDestroy {
  @Input() disabled = false;
  @Input() direction: TransferDirection;
  @Input() selected: BehaviorSubject<TransferToken | null> = new BehaviorSubject<TransferToken | null>(null);
  private ref: DialogRef<TransferToken, TokensSelectDialogComponent>;
  private subs: SubscriptionLike[] = [];

  get name(): string {
    return this.selected.value?.token.name ?? '';
  }

  get nameAlt(): string {
    return `Token ${this.selected.value?.token.name ?? ''}`;
  }

  get symbol(): string {
    const symbol = this.selected.value?.token.symbol;
    return symbol ?? 'Token';
  }

  get image(): string {
    const icon = this.selected.value?.token.logoURI.split('/').pop();
    return icon ? `/assets/tokens/${icon}` : `/assets/icons/token.png`;
  }

  open(e: Event): void {
    e.stopPropagation();
    const config: DialogConfig<TokensDialogData, DialogRef<TransferToken, TokensSelectDialogComponent>> = {
      backdropClass: `dialog-backdrop`,
      data: { direction: this.direction }
    };
    this.ref = this.d.open(TokensSelectDialogComponent, config);
    this.subs.push(this.ref.closed.pipe(tap(token => {
      if (token) {
        this.writeValue(token);
      }
    })).subscribe());
  }

  onChangeFn = (_: any) => {
    this.selected.next(_);
  };

  onTouchedFn = () => {
  };

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.cdr.detectChanges();
  }

  writeValue(token: TransferToken): void {
    this.selected.next(token);
    this.onChangeFn(token);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    itemUnsubscribe(this.subs);
  }

  constructor(private d: Dialog, private cdr: ChangeDetectorRef) {
  }
}
