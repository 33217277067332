import { Injectable } from '@angular/core';
import {
  ComputeBudgetInstruction,
  ComputeBudgetInstructionType,
  ComputeBudgetProgram,
  LAMPORTS_PER_SOL,
  Transaction,
  TransactionInstruction
} from '@solana/web3.js';
import { Amount } from '@neonevm/token-transfer-core';
import { BehaviorSubject, Observable, startWith } from 'rxjs';
import { Big } from 'big.js';
import { environment } from '../../environments/environment';
import { DataStorage, NEON, priorityFeeLamports, simulateTransaction, TRANSACTION_FEE, UNITS_LIMIT } from '../../utils';
import { PriorityFee, PriorityFeeRec, PriorityFeesResponse, TransactionPriorityFee } from '../../models';
import { SolanaWalletService } from './solana-wallet.service';
import { PythService } from './pyth.service';

@Injectable()
export class PriorityFeeService extends DataStorage<TransactionPriorityFee> {
  _data = { priorityFee: { units: 0, fees: { standard: 0, medium: 0, fast: 0 } } };
  fees: PriorityFee[] = [
    { id: 0, label: `Standard`, value: 1, amount: 0, reward: 'solana', type: 'standard' },
    { id: 1, label: `Medium`, value: 3, amount: 0, reward: 'solana', type: 'medium' },
    { id: 2, label: `Fast`, value: 5, amount: 0, reward: 'solana', type: 'fast' }
  ];
  customFee: PriorityFee = { id: 3, label: `Custom`, value: 0, amount: 1, reward: 'solana', type: 'custom' };
  selected$ = new BehaviorSubject<PriorityFee>(this.fees[1]);
  private computeInstructionTypes: (ComputeBudgetInstructionType | boolean)[] = ['SetComputeUnitLimit', 'SetComputeUnitPrice'];
  private priorityFeesDefault: PriorityFeesResponse = {
    priorityFeeLevels: { high: 15e3, low: 1e3, medium: 5e3, min: 1e3, unsafeMax: 2e4, veryHigh: 2e4 }
  };

  get data$(): Observable<TransactionPriorityFee> {
    return super.data$.pipe(startWith({ priorityFee: { units: 0, fees: { standard: 0, medium: 0, fast: 0 } } }));
  }

  receiveFeeCalc = (priorityFee: PriorityFee, amount: Amount, symbol: string, direction: string, reward: string): Big => {
    // @ts-ignore
    const amountBig = new Big(amount ? amount : 0);
    switch (symbol) {
      case NEON:
        if (direction === 'solana' && reward === 'neon' && amountBig.gt(0)) {
          const priorityFeeLamport = priorityFeeLamports(priorityFee.amount, this.data?.priorityFee.units).add(TRANSACTION_FEE).round();
          const solPerNeon = this.pyth.solPerNeon;
          const fee = priorityFeeLamport.times(solPerNeon).div(LAMPORTS_PER_SOL);
          return amountBig.minus(fee);
        }
    }
    return amountBig;
  };

  transactionPriorityFee = async (transaction: Transaction, microLamports: number): Promise<Transaction> => {
    const { value } = await simulateTransaction(this.solana.connection, transaction, 'finalized');
    try {
      const units = Math.round((value?.unitsConsumed ? value.unitsConsumed : UNITS_LIMIT) * 1.5);
      const computePrice = ComputeBudgetProgram.setComputeUnitPrice({ microLamports });
      const computeLimit = ComputeBudgetProgram.setComputeUnitLimit({ units });
      const instructions: TransactionInstruction[] = [];
      for (const instruction of transaction.instructions) {
        if (!this.computeInstructionTypes.includes(instruction.programId.equals(ComputeBudgetProgram.programId) && ComputeBudgetInstruction.decodeInstructionType(instruction))) {
          instructions.push(instruction);
        }
      }
      instructions.unshift(computePrice);
      instructions.unshift(computeLimit);
      transaction.instructions = instructions;
      const rec = await this.getPriorityFeeEstimate(transaction);
      const fees = this.calcPriorityFee(rec);
      this.dataEmit({ transaction, priorityFee: { units, fees }, error: value.err });
    } catch (e) {
      const fees = this.calcPriorityFee(this.priorityFeesDefault);
      const units = Math.round((value?.unitsConsumed ? value.unitsConsumed : UNITS_LIMIT) * 1.5);
      this.dataEmit({ transaction, priorityFee: { fees, units }, error: new Error(`Priority fee estimation failed`) });
      console.log(e);
    }
    return transaction;
  };

  calcFee = (ml: number, mlMax: number, x = 1): number => {
    return ml > 0 ? Math.round(ml / x) : Math.round(mlMax / x);
  };

  calcPriorityFee = (d: PriorityFeesResponse): PriorityFeeRec => {
    const { priorityFeeLevels: { high, veryHigh } } = d;
    const standard = this.calcFee(high, veryHigh, 3);
    const medium = this.calcFee(high, veryHigh, 2);
    const fast = this.calcFee(high, veryHigh, 1);
    return { standard, medium, fast };
  };

  async priorityFee(transaction: Transaction): Promise<any> {
    return this.transactionPriorityFee(transaction, 1);
  }

  priorityFeeReset(): void {
    this.dataEmit({ priorityFee: { units: 0, fees: { standard: 0, medium: 0, fast: 0 } } });
  }

  async getPriorityFeeEstimate(transaction: Transaction, options = { includeAllPriorityFeeLevels: true }): Promise<PriorityFeesResponse> {
    const id = Date.now();
    const accountKeys = this.getWritableAccounts(transaction);
    const response = await fetch(environment.helius.url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, jsonrpc: '2.0', method: 'getPriorityFeeEstimate', params: [{ accountKeys, options }] })
    });
    const data = await response.json();
    return data.result;
  }

  getWritableAccounts(transaction: Transaction): string[] {
    const instructions = transaction.instructions;
    const result: string[] = [];
    for (const instruction of instructions) {
      for (const key of instruction.keys) {
        if (key.isWritable && !result.includes(key.pubkey.toBase58())) {
          result.push(key.pubkey.toBase58());
        }
      }
    }
    return result;
  }

  clean(): void {
    this.selected$.next(this.fees[1]);
  }

  constructor(private solana: SolanaWalletService, private pyth: PythService) {
    super();
  }
}
