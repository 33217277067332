import { Injectable } from '@angular/core';
import { captureException, captureMessage, setUser } from '@sentry/angular-ivy';
import { Primitive } from '@sentry/types';
import { combineLatest, map, SubscriptionLike } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import {
  ProxyStatusService,
  SolanaWalletService,
  TokenTransferService,
  WalletConnectService
} from '../../token-transfer/services';
import { format, itemUnsubscribe, neonLog, solanaLog } from '../../utils';
import { TransferDirection, TransferTokenFormData } from '../../models';

@Injectable()
export class SentryLogService {
  private sub: SubscriptionLike[] = [];

  get user(): any {
    return {
      chain: this.p.chainId,
      username: `${this.s.publicKey.toBase58()};${this.n.address}`,
      solana: `${this.s.provider?.name ? `${this.s.provider.name}; ` : ''}${this.s.publicKey.toBase58()}`,
      neon: `${this.n.wallet$.value?.name ? `${this.n.wallet$.value?.name}; ` : ''}${this.n.address}`
    };
  }

  dataForTransferLog = (data: TransferTokenFormData): Record<string, Primitive> => {
    const { direction, token, amount, rewardAmount, rewardFrom } = data;
    const date = format(new Date());
    const balance = `Solana: ${token.amountView(TransferDirection.solana).toString()} ${token.token.symbol}, Neon: ${token.amountView(TransferDirection.neon).toString()} ${token.token.symbol}`;
    const solana = `${this.s.provider.name ? `${this.s.provider.name}; ` : ''}${this.s.publicKey?.toBase58()}`;
    const neon = this.n.address;
    const td = `${token.token.name};${token.token.address ? ` ${token.token.address};` : ''}${token.token.address_spl ? ` spl:${token.token.address_spl};` : ''}`;
    const directionData = `From: ${direction === 'neon' ? neon : solana}; to: ${direction === 'solana' ? neon : solana}`;
    const chainId = this.p.chainId;
    return { date, direction: directionData, amount, rewardAmount, rewardFrom, td, balance, solana, neon, chainId };
  };

  captureMessage = (message: string): void => {
    const user = this.user;
    captureMessage(message, { user, level: 'debug' });
  };

  init(): void {
    this.sub.push(combineLatest([this.s.publicKey$, this.n.address$]).pipe(
      filter(([s, n]) => !!s && !!n),
      tap(() => {
        setUser(this.user);
      })).subscribe());
    this.sub.push(this.transfer.transferLog$.pipe(
      filter(d => !!d.error),
      map((d) => {
        const { error, data, transaction } = d;
        const { solana, neon } = transaction ?? {};
        const result: any = {};
        if (data) {
          result.data = this.dataForTransferLog(data);
        }
        if (error) {
          result.error = error?.error ? error.error : error;
        }
        if (solana) {
          result.solanaLog = solanaLog(solana.transaction, solana.signature);
        }
        if (neon) {
          result.neonLog = neonLog(neon.transaction, neon.signature);
        }
        return result;
      }),
      tap(result => {
        const user = this.user;
        captureException(result, { user, level: 'error' });
      })).subscribe());
  }

  destroy(): void {
    itemUnsubscribe(this.sub);
  }

  constructor(private s: SolanaWalletService, private n: WalletConnectService, private p: ProxyStatusService,
              private transfer: TokenTransferService) {
  }
}
