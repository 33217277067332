import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TokenTransferService } from '../../services';
import { BehaviorSubject, SubscriptionLike, tap } from 'rxjs';
import { PendingStatus, TransferDirection } from '../../../models';
import { itemUnsubscribe } from '../../../utils';

@Component({
  selector: 'app-transfer-progress',
  templateUrl: './transfer-progress.component.html',
  styleUrl: './transfer-progress.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferProgressComponent implements OnInit, OnDestroy {
  direction: TransferDirection;
  progress$ = new BehaviorSubject(0);
  private subs: SubscriptionLike[] = [];
  isSignature = new BehaviorSubject(false);
  isSigned = new BehaviorSubject(false);
  isFinished = new BehaviorSubject(false);

  get from(): string {
    const icon = this.direction === TransferDirection.solana ? 'solana' : 'neon';
    return `/assets/icons/${icon}.svg`;
  }

  get to(): string {
    const icon = this.direction === TransferDirection.solana ? 'neon' : 'solana';
    return `/assets/icons/${icon}.svg`;
  }

  ngOnInit() {
    this.subs.push(this.transfer.formData$.pipe(tap(data => {
      const { token, amount, direction } = data;
      this.direction = direction;
    })).subscribe());
    this.subs.push(this.transfer.pendingStatus$.pipe(tap(size => {
      switch (size) {
        case PendingStatus.started: {
          this.progress$.next(0);
          break;
        }
        case PendingStatus.wrap:
        case PendingStatus.unwrap:
        case PendingStatus.signature: {
          this.progress$.next(50);
          setTimeout(() => {
            this.isSignature.next(true);
          }, 5e2);
          break;
        }
        case PendingStatus.signed: {
          this.progress$.next(100);
          break;
        }
        case PendingStatus.confirmed: {
          this.progress$.next(100);
          setTimeout(() => {
            this.isSigned.next(true);
            this.isSignature.next(false);
            this.isFinished.next(true);
          }, 5e2);
          break;
        }
        case PendingStatus.rejected: {
          this.progress$.next(0);
          break;
        }
      }
    })).subscribe());
  }

  ngOnDestroy() {
    itemUnsubscribe(this.subs);
  }

  constructor(public transfer: TokenTransferService) {
  }
}
