import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { TransferDirection, TransferToken } from '../../../models';
import { collapseAnimation } from '../../../shared/animations';
import { NEON, SOL } from '../../../utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SolanaWalletService, WalletConnectService } from '../../services';

@Component({
  selector: 'app-neon-transaction-fee',
  templateUrl: './neon-transaction-fee.component.html',
  styleUrls: ['./neon-transaction-fee.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseAnimation(150)],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NeonTransactionFeeComponent),
    multi: true
  }]
})
export class NeonTransactionFeeComponent implements ControlValueAccessor {
  @Input() token: TransferToken;
  @Input() direction: TransferDirection;
  @Input() disabled = false;
  @Input() amount: string;
  @Input() reward: 'solana' | 'neon' = 'solana';
  @Input() loading: Observable<boolean> = of(false);
  @Input() fee: Observable<string> = of('');
  @Input() feeUSD: Observable<string> = of('');
  @Input() error: Observable<string> = of('');

  showBody = false;
  value = '';

  items = [
    { id: 0, label: 'Neon', value: 'neon', token: 'NEON' },
    { id: 1, label: 'Solana', value: 'solana', token: 'SOL' }
  ];

  get network(): string {
    return this.direction === 'solana' ? 'Neon EVM' : 'Solana';
  }

  get selected(): any {
    return this.items.find(i => i.value === this.value);
  }

  get isShowTransactionFee(): boolean {
    return this.token?.token.symbol === NEON && this.direction === TransferDirection.solana;
  }

  get gasToken(): string {
    return this.direction === TransferDirection.solana ? SOL : NEON;
  }

  get gasTokenIcon(): string {
    return `/assets/icons/${this.direction === TransferDirection.solana ? 'solana' : 'neon'}.svg`;
  }

  get isDisabled(): boolean {
    return this.disabled || !this.amount;
  }

  toggleBody(): void {
    this.showBody = !this.showBody;
    this.ga.event('gas_token_click', undefined, undefined, undefined, undefined, {
      neon_account: this.wc.address$.value,
      solana_account: this.solana.publicKey.toString()
    });
  }

  icon(token: any): string {
    return `/assets/icons/${token.value}.svg`;
  }

  isSelected = (item: any): boolean => this.value === item.value;

  select = (item: any): void => {
    if (!this.isSelected(item)) {
      this.writeValue(item.value);
    }
    this.ga.event(`gas_token_change_${item.token}`, undefined, undefined, undefined, undefined, {
      neon_account: this.wc.address$.value,
      solana_account: this.solana.publicKey.toString(),
      gas_token: item.token
    });
  };

  onChangeFn = (value: string) => {
    this.value = value?.toString();
  };

  onTouchedFn = () => {
  };

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value?.toString();
    this.onChangeFn(this.value);
    this.cdr.detectChanges();
  }

  onTouched() {
    this.onTouchedFn();
  }

  constructor(private cdr: ChangeDetectorRef, private ga: GoogleAnalyticsService, public wc: WalletConnectService, private solana: SolanaWalletService) {
  }
}
