import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from '../environments/environment';

import { LayoutModule } from '../layout/layout.module';
import { TokenTransferModule } from '../token-transfer/token-transfer.module';
import { NotificationsModule } from '../notifications';

import { AppComponent } from './components/app/app.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';

const providers = environment.production ? [
  { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false }) },
  { provide: TraceService, deps: [Router] },
  {
    provide: APP_INITIALIZER, useFactory: () => () => {
    }, deps: [TraceService], multi: true
  }] : [];

@NgModule({
  declarations: [AppComponent, CookiePolicyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    TokenTransferModule,
    AngularSvgIconModule,
    NotificationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.measurementId),
    NgxGoogleAnalyticsRouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...providers],
  bootstrap: [AppComponent]
})
export class AppModule {
}
