<app-banner></app-banner>
<app-header></app-header>
<app-content>
  <app-token-transfer-form (formSubmit)='tokenTransfer($event)' [resetForm]='resetForm'
                           [loading]='loading'></app-token-transfer-form>
</app-content>
<app-footer>
  <div class='absolute right-[8px] bottom-[8px] hidden'>{{version}}</div>
</app-footer>
<app-cookie-policy class='z-[9999]'></app-cookie-policy>
<app-notifications></app-notifications>
