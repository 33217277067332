import { ChangeDetectionStrategy, Component } from '@angular/core';
import 'neon-web-components/src/components/cookie-control';

@Component({
  selector: 'app-cookie-policy',
  template: `
    <cookie-control
      linkTarget='true'
      policyUrl='https://neon-labs.org/cookie-policy'
      policyUrlClass='underline'
      containerClass='w-[calc(100%-40px)] sm:w-72 absolute z-50 bottom-5 right-5 bg-[#161B26] rounded-[12px] border border-br-secondary p-5'
      titleClass='text-[#CECFD2] mb-3 font-bold text-[18px]'
      descriptionClass='text-[#94969C] text-[12px] leading-[15px] mb-[10px]'
      acceptCtaClass='font-bold text-white transition-colors duration-300 hover:text-[#fff] text-sm'
      postponeCtaClass='font-bold ml-2 transition-colors duration-300 text-[#94969C] hover:text-[#CECFD2] text-sm'
    ></cookie-control>`,
  styleUrls: ['./cookie-policy.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyComponent {
}
