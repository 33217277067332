import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable, of, SubscriptionLike, tap } from 'rxjs';
import { fractionLength, itemUnsubscribe, NEON, priorityFeeLamports, SOL, TRANSACTION_FEE } from '../../../utils';
import { PriorityFee, PriorityFeeData, TransferDirection } from '../../../models';
import { collapseAnimation } from '../../../shared/animations';
import {
  PriorityFeeService,
  PythService,
  SolanaWalletService,
  TokenTransferFeeService,
  WalletConnectService
} from '../../services';

@Component({
  selector: 'app-solana-priority-fee',
  templateUrl: './solana-priority-fee.component.html',
  styleUrls: ['./solana-priority-fee.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseAnimation(150)],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SolanaPriorityFeeComponent),
    multi: true
  }]
})
export class SolanaPriorityFeeComponent implements OnInit, OnDestroy, PriorityFeeData, ControlValueAccessor {
  @Input() reward: 'solana' | 'neon';
  @Input() direction: TransferDirection;
  @Input() disabled = false;
  @Input() amount: string;
  @Input() error: Observable<string> = of('');
  @Input() errorDescription: string = '';
  @Input() loading: Observable<boolean> = of(false);
  showBody = false;
  fees = this.fee.fees;
  customFee = this.fee.customFee;
  selected$ = this.fee.selected$;
  feeQuestion = `Help your transaction be processed faster<br/>by paying a higher priority fee`;
  private sub: SubscriptionLike[] = [];

  get isDisabled(): boolean {
    return this.disabled || !this.amount;
  }

  isSelected = (item: PriorityFee): boolean => item.id === this.fee.selected$.value?.id;

  calcValue = (item: PriorityFee): string => {
    const { neonFee, solanaFee } = this.transferFee.transferFee$.value;
    const units = this.fee.data.priorityFee?.units;
    if (item.amount > 0 && units && (neonFee.gt(0) || solanaFee.gt(0))) {
      const symbol = this.reward === 'neon' ? NEON : SOL;
      const priorityFee = priorityFeeLamports(item.amount, units).add(TRANSACTION_FEE).round();
      if (this.reward === 'neon') {
        const solPerNeon = this.pyth.solPerNeon;
        const fee = priorityFee.times(solPerNeon).div(LAMPORTS_PER_SOL);
        const fraction = fractionLength(fee.toNumber());
        return `${fee.lte(1e-6) ? '<0.000001' : parseFloat(fee.toFixed(fraction))} ${symbol}`;
      } else {
        const fraction = fractionLength(priorityFee.toNumber());
        const fee = priorityFee.div(LAMPORTS_PER_SOL);
        return `${fee.lte(1e-6) ? '<0.000001' : parseFloat(fee.toFixed(fraction))} ${symbol}`;
      }
    }
    return ``;
  };

  calcFeeUSD = (item: PriorityFee): string => {
    const { neonFee, solanaFee } = this.transferFee.transferFee$.value;
    const units = this.fee.data.priorityFee?.units;
    if (item.amount > 0 && units && (neonFee.gt(0) || solanaFee.gt(0))) {
      const priorityFee = priorityFeeLamports(item.amount, units).add(TRANSACTION_FEE).round();
      if (this.reward === 'neon') {
        const solPerNeon = this.pyth.solPerNeon;
        const neon = this.pyth.neonPrice;
        const fee = priorityFee.times(solPerNeon).div(LAMPORTS_PER_SOL).times(neon);
        const fraction = fractionLength(fee.toNumber());
        return `$${fee.lte(1e-4) ? '<0.0001' : fee.toFixed(fraction < 4 ? fraction : 4)}`;
      } else {
        const sol = this.pyth.solPrice;
        const fee = priorityFee.div(LAMPORTS_PER_SOL).times(sol);
        const fraction = fractionLength(fee.toNumber());
        return `$${fee.lte(1e-4) ? '<0.0001' : fee.toFixed(fraction < 4 ? fraction : 4)}`;
      }
    }
    return ``;
  };

  trackByFn = (i: number, item: PriorityFee): number => item.id ?? i;

  select = (item: PriorityFee): void => {
    this.writeValue(item.value);
    this.fee.selected$.next(item);
    this.ga.event('priority_fee_set', undefined, undefined, undefined, undefined, {
      neon_account: this.wc.address$.value,
      solana_account: this.solana.publicKey.toString(),
      selected_fee: this.fee.selected$.value.type
    });
    this.cdr.detectChanges();
  };

  icon = (item: PriorityFee): string => `/assets/icons/fee-${item.type}.svg`;

  toggleBody(): void {
    this.showBody = !this.showBody;
    if (this.showBody) {
      this.ga.event('priority_fee_open', undefined, undefined, undefined, undefined, {
        neon_account: this.wc.address$.value,
        solana_account: this.solana.publicKey.toString()
      });
    }
  }

  onChangeFn = (value: any) => {
    // this.value = value;
  };

  onTouchedFn = () => {
  };

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  writeValue(size: number): void {
    this.onChangeFn(size);
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.sub.push(this.fee.data$.pipe(tap(d => {
      for (const fee of this.fee.fees) {
        fee.amount = d.priorityFee.fees[fee.type] ?? 0;
      }
      this.cdr.markForCheck();
    })).subscribe());
    this.sub.push(this.transferFee.transferFee$.pipe(tap(_ => {
      this.cdr.markForCheck();
    })).subscribe());
  }

  ngOnDestroy(): void {
    itemUnsubscribe(this.sub);
  }

  constructor(public fee: PriorityFeeService, private transferFee: TokenTransferFeeService, private pyth: PythService,
              private cdr: ChangeDetectorRef, private ga: GoogleAnalyticsService, public wc: WalletConnectService, private solana: SolanaWalletService) {
  }
}
