import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublicKey } from '@solana/web3.js';
import { BehaviorSubject, combineLatest, map, Observable, SubscriptionLike, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NeonTransferFee, ServiceWallet, TransactionData } from '../../models';
import { itemUnsubscribe } from '../../utils';
import { SolanaWalletService } from './solana-wallet.service';
import { WalletConnectService } from './wallet-connect.service';

@Injectable({ providedIn: 'root' })
export class NeonTransferFeeService {
  serviceWallet$ = new BehaviorSubject<PublicKey | null>(null);
  private host = `${environment.urls.neonpassService}/api`;
  private subs: SubscriptionLike[] = [];

  serviceWallet(): Observable<PublicKey> {
    return this.http.get<ServiceWallet>(`${this.host}/info/wallet`).pipe(map(d => new PublicKey(d.wallet)), tap(this.dataEmit));
  }

  neonTransferFee(): Observable<NeonTransferFee> {
    return this.http.get<NeonTransferFee>(`${this.host}/info/neon/price`);
  }

  sendTransaction(data: TransactionData): Observable<any> {
    return this.http.post<TransactionData>(`${this.host}/transactions/process`, data);
  }

  init(): void {
    this.subs.push(combineLatest([this.solana.publicKey$, this.neon.address$]).pipe(
      filter(([s, n]) => !!s && !!n),
      switchMap(() => this.serviceWallet())).subscribe());
  }

  destroy(): void {
    itemUnsubscribe(this.subs);
  }

  private dataEmit = (d: PublicKey): void => {
    this.serviceWallet$.next(d);
  };

  constructor(private http: HttpClient, private solana: SolanaWalletService, private neon: WalletConnectService) {
  }
}
