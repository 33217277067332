import { Buffer } from 'buffer';
import { environment } from './environments/environment';

(window as any).global = window;
global.Buffer = Buffer;

global.process = {
  env: { DEBUG: undefined },
  version: '',
} as any;

if (environment.production) {
  window.console.log = () => {
  };
}

declare global {
  interface Window{
    ethereum?: any
  }
}
