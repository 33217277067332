import { erc20Abi, EthersSignedTransaction, NEON_TOKEN_MINT_DECIMALS, SPLToken } from '@neonevm/token-transfer-core';
import { PublicKey } from '@solana/web3.js';
import { Big } from 'big.js';
import { Contract, Interface, keccak256, Transaction, TransactionRequest, Wallet } from 'ethers';
import { Buffer } from 'buffer';
import { Amount, NeonTokenBalance } from '../models';
import { NEON } from './consts';

export async function neonBalance(provider: any): Promise<NeonTokenBalance> {
  const signer = await provider.getSigner();
  const b = await provider.getBalance(signer.address);
  const value = typeof b === 'bigint' ? b : BigInt(0);
  return {
    value,
    symbol: NEON,
    decimals: NEON_TOKEN_MINT_DECIMALS,
    formatted: new Big(value.toString()).div(Big(10).pow(NEON_TOKEN_MINT_DECIMALS)).toString()
  };
}

export async function tokenBalance(provider: any, token: SPLToken, abiContract: any, method = 'balanceOf'): Promise<NeonTokenBalance> {
  const signer = await provider.getSigner();
  const tokenContract = new Contract(token.address, abiContract, signer);
  const result: NeonTokenBalance = {
    value: 0n,
    symbol: NEON,
    decimals: NEON_TOKEN_MINT_DECIMALS,
    formatted: ''
  };
  if (tokenContract['balanceOf']) {
    const m = tokenContract[method];
    const b = await m(signer.address);
    const value = typeof b === 'bigint' ? b : 0n;
    result.value = value;
    result.formatted = new Big(value.toString()).div(Big(10).pow(token.decimals)).toString();
  }
  return result;
}

export function claimTransactionData(associatedToken: PublicKey, neonWallet: string, amount: Amount): string {
  const tokenContract = new Interface(erc20Abi);
  return tokenContract.encodeFunctionData('claimTo', [associatedToken.toBuffer(), neonWallet, amount]);
}

export async function useTransactionFromSignerEthers(claimData: string, walletSigner: Wallet, address: string): Promise<EthersSignedTransaction> {
  const transaction: TransactionRequest = Transaction.from({
    type: 0,
    to: address, // contract address
    data: claimData,
    gasLimit: `0x5F5E100`, // 100000000
    gasPrice: `0x0`
  });
  transaction.nonce = await walletSigner.getNonce();
  const rawTransaction = await walletSigner.signTransaction(transaction);
  return { rawTransaction };
}

export function signerPrivateKey(solanaWallet: PublicKey, neonWallet: string): string {
  return keccak256(Buffer.from(`${neonWallet.slice(2)}${solanaWallet.toBase58()}`, 'utf-8'));
}
