import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { tap } from 'rxjs/operators';
import { itemUnsubscribe } from '../../../utils';
import { NotificationService } from '../../../notifications';
import { NeonChainService, WalletConnectService } from '../../services';
import { ChainWarningData } from '../../../models';

@Component({
  selector: 'app-chain-switch',
  templateUrl: './chain-switch.component.html',
  styleUrls: ['./chain-switch.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChainSwitchComponent implements OnInit, OnDestroy {
  @Input() data: ChainWarningData;
  sub: SubscriptionLike[] = [];

  networkSwitch(): void {
    this.sub.push(this.chain.chainSwitch().pipe(tap(_ => {
      this.n.close(this.data.id);
    })).subscribe());
  }

  ngOnInit() {
    this.neon.connected$.pipe(tap(c => {
      if (!c) {
        this.n.close(this.data.id);
      }
    })).subscribe();
  }

  ngOnDestroy(): void {
    itemUnsubscribe(this.sub);
  }

  constructor(public chain: NeonChainService, private neon: WalletConnectService, private n: NotificationService) {
  }
}
