import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NEON_TOKEN_DECIMALS } from '@neonevm/token-transfer-core';
import { BehaviorSubject, combineLatest, Observable, SubscriptionLike, switchMap, tap } from 'rxjs';
import { itemUnsubscribe, NEON, W_NEON } from '../../../utils';
import { TransferDirection, TransferToken } from '../../../models';
import { ProxyStatusService, SolanaWalletService, WalletConnectService } from '../../services';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-token-amount',
  templateUrl: './token-amount.component.html',
  styleUrls: ['./token-amount.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TokenAmountComponent),
    multi: true
  }]
})
export class TokenAmountComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() token: Observable<TransferToken>;
  @Input() direction: Observable<TransferDirection>;
  @Input() disabled = false;
  @Output() maxEmit = new EventEmitter<TransferToken>();
  balance$ = new BehaviorSubject(``);
  separatorLimit = '99999999';
  focus = false;
  value = '';
  private _token: TransferToken;
  private _direction: TransferDirection;
  private subs: SubscriptionLike[] = [];
  private timeout: any;

  @ViewChild('amount') amount: ElementRef<HTMLInputElement>;

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('class.focus')
  get hasFocus(): boolean {
    return this.focus;
  }

  @HostListener('click')
  onClick() {
    this.amount.nativeElement.focus();
  }

  get decimals(): number {
    if (this._token) {
      const balance = this._token.tokenAmount(this._direction);
      if ([NEON, W_NEON].includes(this._token.token.symbol)) {
        return NEON_TOKEN_DECIMALS;
      }
      if (balance) {
        return balance.decimals;
      }
    }
    return 6;
  }

  get mask(): string {
    return `separator.${this.decimals}`;
  }


  get maxDisabled(): boolean {
    return this.disabled || this._token?.amount(this._direction).eq(0);
  }

  max(): void {
    if (!this.disabled && this._token) {
      this.maxEmit.emit(this._token);
      this.ga.event('max_asset_amount', undefined, undefined, undefined, undefined, {
        neon_account: this.wc.address$.value,
        solana_account: this.solana.publicKey.toString(),
        maxAmount: this.value
      });
    }
  }

  onChangeFn = (value: any) => {
    this.value = value;
  };

  onTouchedFn = () => {
  };

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  writeValue(size: string): void {
    if(size) {
      this.ga.event('asset_amount', undefined, undefined, undefined, undefined, {
        neon_account: this.wc.address$.value,
        solana_account: this.solana.publicKey.toString(),
        amount: size
      });
    }
    this.value = size?.toString().replace(',', '.');
    this.onChangeFn(this.value);
    this.cdr.detectChanges();
  }

  inputValue(e: any): void {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.writeValue(e.target.value);
    }, 300);
  }

  onTouched() {
    this.onTouchedFn();
  }

  ngOnInit(): void {
    this.subs.push(combineLatest([this.token, this.direction]).pipe(switchMap(([token, direction]) => {
        this._token = token;
        this._direction = direction;
        return this.proxy.neonTokenAmount$(token, direction);
      }),
      tap(b => {
        const balance = b.gt(0) ? b?.gte(1e-6) ? b.toString() : `<0.000001` : ``;
        this.balance$.next(balance.length > 0 ? `Balance: ${balance}` : ``);
      })).subscribe());
  }

  ngOnDestroy(): void {
    itemUnsubscribe(this.subs);
  }

  constructor(private cdr: ChangeDetectorRef, private proxy: ProxyStatusService, private ga: GoogleAnalyticsService, public wc: WalletConnectService, private solana: SolanaWalletService) {
  }
}
