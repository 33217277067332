import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Counter } from '../../../utils';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrl: './counter.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CounterComponent implements AfterViewInit, OnChanges {
  @Input() value = '';
  _counter: Counter;

  @HostBinding('class.counter') counter = true;

  ngAfterViewInit() {
    this._counter = new Counter(this.ref.nativeElement);
    this._counter.countUp(this.value);
  }

  ngOnChanges(_: SimpleChanges) {
    if (this._counter && typeof this.value === 'string') {
      this._counter.countUp(this.value);
    }
  }

  constructor(private ref: ElementRef) {
  }
}
