<div class='header border-b border-[#333741] cursor-default'>
  <div class='label'>Time to<span class='amount'>{{ network }}</span></div>
  <div class='label'>
    <span>~30 seconds</span>
  </div>
</div>
@if (isShowTransactionFee) {
  <div (click)='toggleBody()' class='header cursor-pointer' [class.closed]='!showBody'>
    <div class='label'>Gas Token</div>
    <div class='label'>
      <span class='flex flex-row items-center gap-[6px]'>
        <span class='amount'>{{ selected.token }}</span>
        <img loading='lazy' [src]='icon(selected)' [alt]='selected' class='w-[16px] h-[16px]'>
      </span>
      <button type='button' class='arrow-down' [class.active]='showBody'>
        <img src='/assets/icons/arrow-down.svg' alt='Gas Token button' width='24' height='24' />
      </button>
    </div>
  </div>
  <div class='body mx-[-4px]' [@collapse]='showBody'>
    <div class='flex flex-row p-[4px]'>
      @for (item of items; track item.id) {
        <button type='button' class='button-group' (click)='select(item)' [class.selected]='isSelected(item)' [disabled]='isDisabled'>
          <img loading='lazy' [src]='icon(item)' [alt]='selected' class='w-[20px] h-[20px]' width='20' height='20'>
          <span class='text'>{{ item.label }}</span>
        </button>
      }
    </div>
  </div>
} @else {
  <div class='header closed'>
    <div class='label'>Gas Token</div>
    <div class='label'>
      <span class='flex flex-row items-center gap-[6px]'>
        <span class='amount'>{{ gasToken }}</span>
        <img loading='lazy' [src]='gasTokenIcon' alt='Neon' class='w-[16px] h-[16px]' width='16' height='16'>
      </span>
    </div>
  </div>
}
<div class='header cursor-default' [class.closed]='!(error|async)'>
  <app-transfer-gas-fees [direction]='direction' [reward]='reward' [loading]='loading'></app-transfer-gas-fees>
</div>
