export const environment = {
  environment: 'development',
  production: false,
  network: 'devnet',
  chainId: 245022926,
  chainName: 'Neon EVM (DevNet)',
  neon: {
    token_mint: `89dre8rZjLNft7HoupGiyxu3MNftR577ZYu8bHe2kK7g`,
    token_contract: `0x5238c694a8db837fff8c4068859e765b978a7607`,
    token_contract_sol: `0x5238c694a8db837fff8c4068859e765b978a7607`,
    decimals: { token: 9, token_mint: 18 }
  },
  helius: {
    url: `https://atlas.neonevm.org`,
    secure: `https://karine-egne3h-fast-mainnet.helius-rpc.com`,
    api_key: `404b816c-9063-430e-91df-234ebd375d59`
  },
  urls: {
    solana: `https://api.devnet.solana.com`,
    neon: `https://devnet.neonevm.org`,
    everstake: `https://devnet.everstake.one`,
    neonpassService: `https://neonpass-service.neontest.xyz`
  },
  neonScan: 'https://devnet.neonscan.org',
  walletConnect: {
    projectId: 'c297fee968f5e8c588cef7c43dd7e358'
  },
  siteUrl: 'https://devnet.neonpass.live',
  measurementId: 'G-TWRNJK19KX'
};
