import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule, SvgIconComponent } from 'angular-svg-icon';
import { BannerComponent, ContentComponent, FooterComponent, HeaderComponent, MenuComponent } from './components';

@NgModule({
  declarations: [
    BannerComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    SvgIconComponent
  ],
  exports: [
    BannerComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent
  ]
})
export class LayoutModule {
}
