import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SafeHtml } from '@angular/platform-browser';
import { TooltipComponent } from '../components';

@Directive({ selector: '[tooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('tooltip') content: SafeHtml = '';
  private ref: OverlayRef;

  @HostListener('mouseover')
  show(): void {
    if (!this.ref.hasAttached()) {
      const tooltipRef: ComponentRef<TooltipComponent> = this.ref.attach(new ComponentPortal(TooltipComponent));
      tooltipRef.instance.content = this.content as string;
    }
  }

  @HostListener('mouseout')
  hide(): void {
    this.ref.detach();
  }

  @HostListener('click')
  howHide(): void {
    if (!this.ref.hasAttached()) {
      const tooltipRef: ComponentRef<TooltipComponent> = this.ref.attach(new ComponentPortal(TooltipComponent));
      tooltipRef.instance.content = this.content as string;
    } else {
      this.hide();
    }
  }

  @HostListener('window:scroll')
  hideScroll(): void {
    if (this.ref.hasAttached()) {
      this.hide();
    }
  }

  ngOnInit(): void {
    const positionStrategy = this.opb.flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: 26,
        offsetY: 0
      }]);

    this.ref = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy(): void {
    this.hide();
  }

  constructor(private overlay: Overlay, private opb: OverlayPositionBuilder, private elementRef: ElementRef) {
  }
}
