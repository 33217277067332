import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransferDirection, TransferToken } from '../../../models';
import { PriorityFeeService } from '../../services';
import { fractionLength } from '../../../utils';

@Component({
  selector: 'app-token-receive',
  templateUrl: './token-receive.component.html',
  styleUrl: './token-receive.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokenReceiveComponent {
  @Input() token: TransferToken;
  @Input() amount: string;
  @Input() direction: TransferDirection;
  @Input() reward: 'solana' | 'neon';

  get amountView(): Observable<string> {
    return this.fee.selected$.pipe(map(priorityFee => {
      const amount = this.fee.receiveFeeCalc(priorityFee, this.amount, this.symbol, this.direction, this.reward);
      const fraction = fractionLength(amount.toNumber());
      return `${amount.toFixed(fraction)} ${this.symbol}`;
    }));
  }

  get symbol(): string {
    return this.token?.symbol ?? '';
  }

  get icon(): string {
    const icon = this.token?.token.logoURI.split('/').pop();
    return icon ? `/assets/tokens/${icon}` : `/assets/icons/token.png`;
  }

  constructor(private fee: PriorityFeeService) {
  }
}
