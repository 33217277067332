import { DataStorageInterface } from '../models';
import { BehaviorSubject, Observable, ReplaySubject, SubscriptionLike } from 'rxjs';
import { itemUnsubscribe } from './rxjs';

export class DataStorage<T = any> implements DataStorageInterface {
  _data: T;
  _data$: ReplaySubject<T> = new ReplaySubject<T>(0);
  loading = new BehaviorSubject(false);
  subs: SubscriptionLike[] = [];

  dataEmit = (d: T): void => {
    this.loading.next(false);
    this._data = d;
    this._data$.next(this._data);
  };

  loadingFinalize = (): void => {
    this.loading.next(false);
  };

  set data(d: T) {
    this.dataEmit(d);
  }

  get data(): T {
    return this._data;
  }

  get data$(): Observable<T> {
    return this._data$;
  }

  destroy(): void {
    this.loading.next(false);
    itemUnsubscribe(this.subs);
  }

  clean(): void {
    this.data = null as T;
    this.loading.next(false);
  }
}
