import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-transfer-errors',
  templateUrl: './transfer-errors.component.html',
  styleUrl: './transfer-errors.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferErrorsComponent {
  @Input() type: string = 'error';
  @Input() error: string = `Not enough SOL in your Solana wallet`;
  @Input() message: string = `Sending tokens to Solana for the first time, requires you to have a minimum of 0.01 SOL in your Solana wallet.`;

  get icon(): string {
    return `/assets/icons/notification-${this.type}.svg`;
  }
}
