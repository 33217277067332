import { Injectable } from '@angular/core';
import {
  getPythClusterApiUrl,
  getPythProgramKeyForCluster,
  PriceData,
  PythCluster,
  PythHttpClient
} from '@pythnetwork/client';
import { PythHttpClientResult } from '@pythnetwork/client/lib/PythHttpClient';
import { Connection } from '@solana/web3.js';
import { catchError, filter, finalize, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { Big } from 'big.js';
import { DataStorage, NEON, SOL, W_NEON, W_SOL } from '../../utils';
import { SolanaWalletService } from './solana-wallet.service';

@Injectable({ providedIn: 'root' })
export class PythService extends DataStorage<PythHttpClientResult> {
  pythClient: PythHttpClient;

  get neonPerSol(): Big {
    const sol = this.findTokenPriceSync(SOL, this.data);
    const neon = this.findTokenPriceSync(NEON, this.data);
    if (sol?.price && neon?.price && neon.price > 0 && sol.price > 0) {
      return new Big(neon.price).div(sol.price);
    }
    return new Big(0);
  }

  get solPerNeon(): Big {
    const sol = this.findTokenPriceSync(SOL, this.data);
    const neon = this.findTokenPriceSync(NEON, this.data);
    if (sol?.price && neon?.price && neon?.price > 0 && sol?.price > 0) {
      return new Big(sol.price).div(neon.price);
    }
    return new Big(150.0).div(1.5);
  }

  get solPrice(): Big {
    const sol = this.findTokenPriceSync(SOL);
    if (sol && sol.price) {
      return new Big(sol.price);
    } else {
      return new Big(150);
    }
  }

  get neonPrice(): Big {
    const neon = this.findTokenPriceSync(NEON);
    if (neon && neon.price) {
      return new Big(neon.price);
    } else {
      return new Big(1.5);
    }
  }

  tokenSymbol = (symbol: string): string => {
    switch (symbol) {
      case W_NEON:
        return NEON;
      case W_SOL:
        return SOL;
    }
    return symbol;
  };

  findTokenPriceSync = (symbol: string, data: PythHttpClientResult = this.data): PriceData | null => {
    const s = this.tokenSymbol(symbol);
    const id = data?.symbols.findIndex(i => i.includes(s));
    if (id > -1) {
      const symbol = data.symbols[id];
      return data.productPrice.get(symbol)!;
    }
    return null;
  };

  pythData(): Observable<PythHttpClientResult> {
    this.loading.next(true);
    return from(this.pythClient.getData()).pipe(tap(this.dataEmit), catchError(this.pythDataError), finalize(this.loadingFinalize));
  }

  findTokenPrice(symbol: string): Observable<PriceData | null> {
    return this.data$.pipe(map(d => this.findTokenPriceSync(symbol, d)));
  }

  init() {
    const clusterName: PythCluster = 'pythnet';
    const connection: Connection = new Connection(getPythClusterApiUrl(clusterName));
    const pythPublicKey = getPythProgramKeyForCluster(clusterName);
    this.pythClient = new PythHttpClient(connection, pythPublicKey);
    this.subs.push(this.s.connected$.pipe(filter(d => d), switchMap(() => this.pythData())).subscribe());
  }

  destroy() {
    super.destroy();
  }

  private pythDataError = (e: any): Observable<any> => of([]);

  constructor(private s: SolanaWalletService) {
    super();
  }
}
