import { animate, AnimationTriggerMetadata, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';

const DEFAULT_DURATION = 250;
export const collapseAnimation = (duration = DEFAULT_DURATION): AnimationTriggerMetadata => {
  return trigger('collapse', [
    state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
    state('false', style({ height: '0', visibility: 'hidden' })),
    transition('false => true', animate(`${duration}ms ease-in`)),
    transition('true => false', animate(`${duration}ms ease-out`))
  ]);
};
