@if (error|async; as ee) {
  <app-transfer-errors [error]='ee' [message]='errorDescription'></app-transfer-errors>
}
@if (direction === 'solana') {
  <div class='header flex-box-row' [class.closed]='!showBody && !(error|async)' (click)='toggleBody()'>
    <div class='label flex-box-row'>Advanced Mode</div>
    <div class='label flex-box-row'>
      <button class='slide' [class.active]='showBody' type='button'>
        <div class='slide-toggle'></div>
      </button>
    </div>
  </div>
  <div class='body flex-box-col' [@collapse]='showBody'>
    <p class='label flex-box-row'>
      Make your transfer faster
      <app-question-tooltip [text]='feeQuestion'></app-question-tooltip>
    </p>
    @for (fee of fees;track fee.id) {
      <button type='button' class='button-fee flex-box-row' (click)='select(fee)' [class.selected]='isSelected(fee)' [disabled]='isDisabled'>
        <img loading='lazy' [src]='icon(fee)' alt='' width='32' height='32'>
        <div class='flex flex-col justify-start'>
          <div class='flex flex-row items-center gap-[4px]'>
            <span>{{ fee.label }}</span>
            <app-counter [value]='calcFeeUSD(fee)' class='amount'></app-counter>
          </div>
          <app-counter [value]='calcValue(fee)' class='amount flex-box-row'></app-counter>
        </div>
        <div class='radio flex-box-row' [class.selected]='isSelected(fee)'></div>
      </button>
    }
  </div>
}
