<header class='header'>
  <div class='header-item'></div>
  <div class='header-item justify-center items-center'>
    <div class='logo'>
      <img src='/assets/icons/logo.svg' alt='NeonPass' width='25' height='24' />
      <span>NeonPass</span>
    </div>
  </div>
  <div class='header-item justify-end gap-[12px]'>
<!--    <app-network-select></app-network-select>-->
    <app-menu></app-menu>
  </div>
</header>
