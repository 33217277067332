import { NEON } from './consts';
import { Chain } from '@web3modal/scaffold-utils/dist/types/src/ethers/EthersTypesUtil';

export const neonEthersMainnet: Chain = {
  chainId: 245022934,
  name: 'Neon EVM (MainNet)',
  currency: NEON,
  explorerUrl: `https://neonscan.org`,
  rpcUrl: `https://neon-mainnet.everstake.one`
};

export const neonEthersDevnet: Chain = {
  chainId: 245022926,
  name: 'Neon EVM (DevNet)',
  currency: NEON,
  explorerUrl: `https://devnet.neonscan.org`,
  rpcUrl: `https://devnet.neonevm.org`
};
