import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent {
  close(): void {
    this.ref.nativeElement.remove();
  }

  constructor(private ref: ElementRef) {
  }
}
