import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';
import { enterLeaveAnimations } from '../../../shared/animations';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass'],
  animations: [enterLeaveAnimations(150, 100)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  @Input() label = '';
  show = false;

  get showAnimation(): string {
    return this.show ? 'fromBottom' : 'fromBottomOut';
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }

  open(): void {
    this.show = !this.show;
    this.ga.event('hamburger_open');
  }

  constructor(private ref: ElementRef, private ga: GoogleAnalyticsService) {
  }
}
