import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { enterLeaveAnimations } from '../../../shared/animations';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveAnimations()]
})
export class NotificationsComponent implements OnInit {

  ngOnInit(): void {
    this.notification.notifications$.pipe(tap(() => {
      this.cdr.detectChanges();
    })).subscribe();
  }

  constructor(public notification: NotificationService, private cdr: ChangeDetectorRef) {
  }
}
