<div class='flex-box-col w-full gap-[4px] relative'>
  <div class='select-none cursor-default'>
    <app-wallet-button label='From' [type]='transferFromTo.from' [disabled]='disabled|async' [sameNetwork]='sameNetwork' testId='wallet-from'></app-wallet-button>
  </div>
  <button class='switch-button' (click)='toggleDirection()' [disabled]='disabled|async' type='button' data-testid='switch-button' aria-label='Switch wallet button'>
    <img src='/assets/icons/change.svg' [@rotatedState]='state' alt='Change wallet' width='20' height='20' />
  </button>
  <div class='select-none cursor-default'>
    <app-wallet-button label='To' [type]='transferFromTo.to' [disabled]='disabled|async' [sameNetwork]='sameNetwork' testId='wallet-to'></app-wallet-button>
  </div>
</div>
